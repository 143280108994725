import { useContext, useEffect, useRef, useState } from 'react';
import { RatingSelect } from '../components/RatingSelect';
import { ReviewOptions } from '../components/ReviewOptions';
import { STUVideoReview } from '../components/STUVideoReview';
import { STUWrittenReview } from '../components/STUWrittenReview';
import { ThankYouPage } from '../components/ThankYouPage';
import { CompanyContext } from '../contexts/CompanyContext';
import { InviteContext } from '../contexts/InviteContext';
import { ReviewContext } from '../contexts/ReviewContext';
import {
  afterRatingReviewPlatformClick,
  convertDomId,
  getRatingReviewStep,
} from '../services/ReviewProcessService';
import { ErrorState, StepState } from '../types/PropsTypes';
import { startReview, updateReview } from '../services/SoTellUsApiService';
import { Box, LoadingOverlay, Stack, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CompanyLogo } from '../components/CompanyLogo';
import { ErrorModal } from '../components/ErrorModal';
import { RatingError } from '../components/ErrorContents/RatingError';
import { WrittenError } from '../components/ErrorContents/WrittenError';
import DevToolbar from '../components/devtools/DevToolbar';
import NPSInput from '../components/NPSInput';
import { BackButton } from '../components/BackButton';
import { InviteForm } from './InviteForm';
import { SystemContext } from '../contexts/SystemContext';

export function RatingReview(): JSX.Element {
  const {system} = useContext(SystemContext)
  const { company, setCompany } = useContext(CompanyContext);
  const { invite, setInvite } = useContext(InviteContext);
  const { review, setReview } = useContext(ReviewContext);
  const [margin, setMargin] = useState<string>();
  const [step, setStep] = useState<StepState>(
    review
      ? getRatingReviewStep(
          review,
          company?.minStarAllowExternal ? company.minStarAllowExternal : 4,
        )
      : 'rating',
  );
  const [visible, { open, close }] = useDisclosure(false);
  const [errorState, setErrorState] = useState<ErrorState>({
    is400: true,
    show: false,
  });
  const refMainBox = useRef<HTMLDivElement|null>(null);

  const onRatingSelect = (rating: number) => {
    open();
    startReview({ rating }, (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "")
      .then((data) => {
        setReview({ ...data, stars: rating });
        if (invite && invite.type === 'video') {
          setStep('video');
        } else if ((company && rating < company.minStarAllowExternal) || (invite && invite.type === 'text')) {
          setStep('written');
        } else {
          setStep('platform');
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorState({
            is400: false,
            show: true,
          });
        } else {
          setErrorState({
            is400: true,
            show: true,
            title: 'Error',
            children: <RatingError />,
          });
        }
      })
      .finally(() => {
        close();
      });
  };

  const onPlatformSelect = (
    domId: string,
    url: string,
    type?: 'video' | 'text',
  ) => {
    open();
    if (review) {
      setReview({ ...review, platform: domId });
    }
    updateReview({
      reviewId: review?.reviewId ? review.reviewId : '',
      platform: convertDomId(domId),
      rating: review?.stars ? review.stars : 0,
      type: type,
    }, (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "")
      .catch((error) => {
        //Won't handle 400 errors here, as we want to make sure the user gets to where they are supposed to even if their api call is unsuccessful
        if (error.response.status === 401) {
          setErrorState({
            is400: false,
            show: true,
          });
        }
      })
      .finally(() => {
        const nextStep = afterRatingReviewPlatformClick(domId);
        if(nextStep === "thankYou"){
          if(url.length > 0 && navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1){
            const delay = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));
            delay(4000).then(() => {
              setStep(nextStep);
              close();
            });
            window.location.href = url;
          }
          else{
            setTimeout(() => {
              window.open(url, '_blank');
              setStep(nextStep);
              close();
            })

          }
        }else{
            setStep(nextStep);
            close();
        }
      });
  };

  const onWrittenReviewSubmission = (reviewText: string, stars: number, legalJargon : string = '', recaptchaToken?:string|null) => {
    open();
    if (review) {
      setReview({ ...review, review: reviewText });
    }
    updateReview(
      {
        reviewId: review?.reviewId ? review?.reviewId : '',
        platform: 'stu',
        rating: stars,
        type: 'text',
        reviewText: reviewText,
        ...(legalJargon && {
          legalJargon: legalJargon,
          timestamp: Math.floor(Date.now() / 1000), //unix timestamp
        }),
        recaptchaToken: recaptchaToken
      },  
      (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "",
    )
      .then(() => {
        setStep('thankYou');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorState({
            is400: false,
            show: true,
          });
        } else {
          setErrorState({
            is400: true,
            show: true,
            title: 'Error',
            children: <WrittenError />,
          });
        }
      })
      .finally(() => {
        close();
      });
  };

  const onVideoReviewSubmission = (status: 200 | 401) => {
    if (status === 401) {
      setErrorState({
        is400: false,
        show: true,
      });
    } else {
      setStep('thankYou');
    }
  };

  const marginTopResponsive = () => {
    const MOBILE_PERCENTAGE = "0.27";
    const DESKTOP_PERCENTAGE = "0.10";
    if(refMainBox.current && step !== "written" && step !== "video" && step !== "thankYou"){
      const availableWhiteSpace = window.innerHeight - refMainBox.current.offsetHeight;
      const percentage = window.innerWidth < 550 ? MOBILE_PERCENTAGE: DESKTOP_PERCENTAGE;
      return `calc(${availableWhiteSpace}px * ${percentage})`;
    }
    return "0"
  }

  useEffect(()=>{
    setMargin(marginTopResponsive())
  },[step])

  if (company && invite) {
    if (!invite.firstname && !invite.lastname && !company.allowAnonymous) {
      return (
        <InviteForm
          include_contact_info={false}
          onSubmit={(first, last) => {
            setInvite({ ...invite, firstname: first, lastname: last });
            setStep('rating');
          }}
          local_company={company}
          local_invite={invite}
        />
      );
    }



    return (
      <div ref={refMainBox} className="main-box" style={{marginTop: margin}}>
        <ErrorModal
          is400={errorState.is400}
          opened={errorState.show}
          title={errorState.title}
          children={errorState.children}
          onClose={() => {
            setErrorState({
              is400: true,
              show: false,
              title: undefined,
              children: undefined,
            });
          }}
        />
        <Box pos="relative" className="centered-stack">
          <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', position: 'absolute', left: 0 }}>
              {(step === 'written' || step === 'video') && (
                <BackButton
                  setStep={setStep}
                  rating={review?.stars ? review.stars : 0}
                  minStars={company.minStarAllowExternal}
                  context="rating-review"
                />
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
              <CompanyLogo name={company.name} logo={company.logo} />
            </div>
          </div>
          {step === 'rating' ? (
            <>
              {company.isNPS ? (
                <NPSInput
                  company={company}
                  invite={invite}
                  onSelect={onRatingSelect}
                />
              ) : (
                <RatingSelect
                  firstName={invite.firstname}
                  companyName={company.name}
                  onSelect={onRatingSelect}
                />
              )}
            </>
          ) : step === 'platform' ? (
            <Stack w='100%'>
              <Title className='header-text'>
                Select below where you would like to<br/>leave your review
              </Title>
              <ReviewOptions
                platformOptions={company.platformOptions}
                onSelect={onPlatformSelect}
              />
            </Stack>
          ) : step === 'written' ? (
            <STUWrittenReview
              rating={review?.stars ? review.stars : 0}
              companyName={company.name}
              onSubmit={onWrittenReviewSubmission}
              isHipaa={company.isHipaa}
            />
          ) : step === 'video' ? (
            <STUVideoReview onSuccess={onVideoReviewSubmission} companyName={company.name} isHipaa={company.isHipaa} />
          ) : (
            <ThankYouPage />
          )}
        </Box>
        {process.env.NODE_ENV !== 'production' && (
          <DevToolbar
            company={company}
            ctx={'Rating-Review'}
            setCompany={(e) => {
              setCompany(e);
            }}
            step={step}
            setStep={(r) => {
              setStep(r);
            }}
          />
        )}
      </div>
    );
  }

  return <></>;
}
